import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { BsInfoCircleFill } from "react-icons/bs";

const StaticTooltip = (id: string, text: string) => {
  const renderTooltip = (tooltipObj: { id: string; text: string }) => {
    return (
      <Popover id={`tooltip-popover-${id}`} className="popover">
        <Popover.Body>
          <span className="text-cerulean" style={{ fontSize: "12" }}>
            {" "}
            {text}
          </span>
        </Popover.Body>
      </Popover>
    );
  };

  return (
    <OverlayTrigger placement="right" overlay={renderTooltip({ id, text })}>
      <span className="">
        <BsInfoCircleFill style={{ fill: "#00415e", color: "white" }} />
      </span>
    </OverlayTrigger>
  );
};

export default StaticTooltip;
