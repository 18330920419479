import { OverlayTrigger, Popover } from "react-bootstrap";
import { BsInfoCircleFill } from "react-icons/bs";

interface AppProps {
  id: string;
  text: string;
}

const TotalCompTooltip = ({ id, text }: AppProps) => {
  const renderTooltip =
    (tooltipObj: { id: string; text: string }) => (props: any) => {
      return (
        <Popover id={`tooltip-popover-${id}`} {...props} className="popover">
          <Popover.Body>
            <span className="text-cerulean" style={{ fontSize: "12" }}>
              {" "}
              {text}
            </span>
          </Popover.Body>
        </Popover>
      );
    };

  return (
    <OverlayTrigger placement="right" overlay={renderTooltip({ id, text })}>
      <span className="">
        <BsInfoCircleFill style={{ fill: "#00415e", color: "white" }} />
      </span>
    </OverlayTrigger>
  );
};

export default TotalCompTooltip;
