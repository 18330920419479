export interface RuntimeConfig {
  [endpoint_url: string]: string;
  okta_client_id: string;
  okta_client_issuer: string;
}

declare global {
  interface Window {
    runtimeConfig: RuntimeConfig;
  }
}

const runtimeConfig: RuntimeConfig = window.runtimeConfig;
export default runtimeConfig;
