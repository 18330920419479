import axios from "axios";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";

import ColumnChart from "../Chart/ColumnChart";
import PieChart from "../Chart/PieChart";
import PieTotalChart from "../Chart/PieTotalChart";

import DonutChart from "../Chart/DonutChart";

import NoDataFound from "../Common/Snippets/NoDataFound";
import Table from "../Table/Table";
import { ISection } from "../../Interfaces/ISection";
import "./Section.css";
import runtimeConfig from "../../RuntimeConfig";
import { IEmployee } from "../../Interfaces/IEmployee";
import IUserInfo from "../../Interfaces/IUserInfo";

interface ISectionProps {
  userInfo: IEmployee | IUserInfo | undefined;
  sectionId: string;
  year: number;
  format: string;
}

const Section = ({ userInfo, sectionId, year, format }: ISectionProps) => {
  const [data, setData] = useState<ISection>();
  // const url = process.env.REACT_APP_ENDPOINT_URL + "/api/section";
  const url =
    (runtimeConfig.endpoint_url || process.env.REACT_APP_ENDPOINT_URL) +
    "/api/section";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await axios.get(url, { params: { id: sectionId } });
        setData(result.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [sectionId, year, url]);

  const ChartType = (chartType: string) => {
    if (chartType.toLowerCase() === "column") {
      return (
        <ColumnChart userInfo={userInfo} sectionId={data?.id} year={year} />
      );
    }
    if (chartType.toLowerCase() === "pie") {
      return (
        <PieChart
          userInfo={userInfo}
          sectionId={data?.id}
          year={year}
          format={format}
        />
      );
    }
    if (chartType.toLowerCase() === "pietotal") {
      return (
        <PieTotalChart
          userInfo={userInfo}
          sectionId={data?.id}
          year={year}
          format={format}
        />
      );
    }
    if (chartType.toLowerCase() === "donut") {
      return (
        <DonutChart userInfo={userInfo} sectionId={data?.id} year={year} />
      );
    }
  };

  return (
    <>
      {data ? (
        <Container fluid className="p-1 p-sm-5">
          <Row>
            <Col>
              <p id={data.name} className="title text-center pb-5">
                {data.title}
              </p>
            </Col>
          </Row>
          <Row xs={1} md={1} lg={2}>
            <Col className="px-5">
              <label className="text-cerulean">{data.description}</label>
              <Table
                userInfo={userInfo}
                sectionId={data?.id}
                sectionTitle={data.title}
                chartType={data.chartType}
                year={year}
                format={format}
              />
              <label className="text-cerulean">{data.postDescription}</label>
            </Col>
            <Col>{ChartType(data.chartType)}</Col>
          </Row>
        </Container>
      ) : (
        <NoDataFound />
      )}
    </>
  );
};

export default Section;
