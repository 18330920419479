import { useEffect, useState } from "react";
import {
  Chart,
  ChartArea,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
} from "@progress/kendo-react-charts";
import "hammerjs";
import axios from "axios";
import { IPieChart } from "../../Interfaces/IPieChart";
import NoDataFound from "../Common/Snippets/NoDataFound";
import { useOktaAuth } from "@okta/okta-react/";
import "./DonutChart.css";
import runtimeConfig from "../../RuntimeConfig";
import { IEmployee } from "../../Interfaces/IEmployee";
import IUserInfo from "../../Interfaces/IUserInfo";

interface IDonutChartProps {
  userInfo: IEmployee | IUserInfo | undefined;
  sectionId: number | undefined;
  year: number;
}

const css = {
  color: "#FFF",
  fontWeight: "bold",
  fontSize: "18px",
  background: "#00415E",
};

const DonutChart = ({ userInfo, sectionId, year }: IDonutChartProps) => {
  // const { oktaAuth } = useOktaAuth();
  const [data, setData] = useState<IPieChart>();
  // const url = process.env.REACT_APP_ENDPOINT_URL + "/api/chartpie";
  const url =
    (runtimeConfig.endpoint_url || process.env.REACT_APP_ENDPOINT_URL) +
    "/api/chartpie";

  useEffect(() => {
    if (!userInfo) return;

    const fetchData = async () => {
      try {
        // const email = (await oktaAuth.getUser()).email;
        const body = {
          id: 0,
          sectionId: sectionId,
          userId: userInfo?.email,
          year: year,
        };
        const result = await axios.post(url, body);

        setData(result.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [userInfo, year]);

  const calculateSum = () => {
    let d = data;
    let sum = d?.series.reduce((partialSum, a) => partialSum + a.value, 0);

    let formatedNumber = Number(sum).toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    });
    return formatedNumber;
  };

  const labelContent = (props: any) => {
    let formatedNumber = Number(props.dataItem.value).toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    });
    return `${props.dataItem.category} : ${formatedNumber}`;
  };

  const donutCenterRenderer = () => <span style={css}> {calculateSum()} </span>;

  return (
    <>
      {data ? (
        <Chart
          style={{ minHeight: "500px" }}
          donutCenterRender={donutCenterRenderer}
        >
          <ChartArea background="transparent" margin={0} />

          <ChartSeries>
            <ChartSeriesItem
              type="donut"
              data={data.series}
              categoryField="category"
              field="value"
              color="color"
              labels={{
                visible: true,
                content: labelContent,
                position: "outsideEnd",
              }}
            ></ChartSeriesItem>
          </ChartSeries>
          <ChartLegend visible={false} />
        </Chart>
      ) : (
        <NoDataFound />
      )}
    </>
  );
};

export default DonutChart;
