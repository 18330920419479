import runtimeConfig from "./RuntimeConfig";

// const CLIENT_ID = process.env.REACT_APP_OKTA_CLIENT_ID;
// const ISSUER = process.env.REACT_APP_OKTA_ISSUER;
const CLIENT_ID =
  runtimeConfig.okta_client_id || process.env.REACT_APP_OKTA_CLIENT_ID;
const ISSUER =
  runtimeConfig.okta_client_issuer || process.env.REACT_APP_OKTA_ISSUER;
const OKTA_TESTING_DISABLEHTTPSCHECK =
  process.env.REACT_APP_OKTA_TESTING_DISABLEHTTPSCHECK || false;
const BASENAME = process.env.PUBLIC_URL || "";
const REDIRECT_URI =
  process.env.REDIRECT_URI ||
  `${window.location.origin}${BASENAME}/login/callback`;

const oktaConfig = {
  oidc: {
    clientId: CLIENT_ID,
    issuer: ISSUER,
    redirectUri: REDIRECT_URI,
    scopes: ["openid", "profile", "email"],
    pkce: true,
    disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK,
  },
  app: {
    basename: BASENAME,
  },
};

export default oktaConfig;
