import "./Table.css";
import { IRow } from "../../Interfaces/ITable";
import TotalCompTooltip from "../Common/TotalCompTooltip";
import { typeFormatter } from "../../Utilities/util";

interface LabelProps {
  row: IRow;
  idx: number;
  format: string;
}

function handleClick(id: string) {
  if (id.length > 0) window.location.href = id;
}

const Label = ({ row, idx, format }: LabelProps) => {
  const tdRow = (
    <>
      {row.name} &nbsp;
      {row.tooltip && (
        <TotalCompTooltip id={idx.toString()} text={row.tooltip} />
      )}
    </>
  );

  return (
    <tr onClick={() => handleClick(row.hrefTo)}>
      <td>{tdRow}</td>
      <td>{typeFormatter(format)?.format(row.value1)}</td>
    </tr>
  );
};

export default Label;
