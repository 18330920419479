import { Container, Col, Row } from "react-bootstrap";
import StaticTooltip from "../Common/Snippets/StaticToolTip";

const eaToolTip =
  "If eligible, up to $5,250 per year for reimbursement of higher education courses. This Education Reimbursement benefit applies to all full-time regular employees who have worked for North Wind for a minimum of 6 months prior to the beginning of the course. Employee must be meeting all performance expectations to be eligible. To view more eligibility requirements, please review the policy.";
const nwuToolTip =
  "Free personal and professional training and development resources.";

const Additional = () => {
  return (
    <Container fluid className="p-1 p-sm-5">
      <Row>
        <Col>
          <p className="title text-center pb-5">
            Additional Available Company Perks
          </p>
        </Col>
      </Row>
      <Row xs={1} md={1} lg={2}>
        <Col className="px-5">
          <label className="text-cerulean"> </label>
          <table className="table">
            <thead>
              <tr>
                <th>Additional Available Company Perks Breakdown</th>
                <th>North Wind Contribution</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  Educational Assistance {StaticTooltip("999", eaToolTip)}
                </td>
                <td>Up to $5,250.00/year</td>
              </tr>
              <tr>
                <td>
                  North Wind University {StaticTooltip("998", nwuToolTip)}
                </td>
                <td>Average of $188.68/year</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td>Total</td>
                <td>$5,438.68</td>
              </tr>
            </tfoot>
          </table>
        </Col>
        <Col> </Col>
      </Row>
    </Container>
  );
};

export default Additional;
