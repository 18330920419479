import { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";

import { UserConsumer } from "../Components/Auth/UserContext";
import IUserInfo from "../Interfaces/IUserInfo";

type WelcomeProps = {
  userInfo: IUserInfo | undefined;
};

const Welcome = ({ userInfo }: WelcomeProps) => {
  const [username, setUsername] = useState<string | undefined>("");

  useEffect(() => {
    if (userInfo && userInfo.name) {
      setUsername(userInfo.name);
    }
  }, [userInfo]);

  return (
    <Container fluid className="bg-whisper ">
      <Row className="py-2 px-3">
        <Col className="text-right">Hello, {username}</Col>
      </Row>
    </Container>
  );
};

const withContext = () => (
  <UserConsumer>{(userInfo) => <Welcome userInfo={userInfo} />}</UserConsumer>
);

export default withContext;
