import { useEffect, useState, useRef } from "react";
import { Container, Row, Col, Form, Spinner } from "react-bootstrap";
import Additional from "../Components/Table/Additional";
import Summary from "../Components/Summary/Summary";
import Welcome from "../Layout/Welcome";
import Section from "../Components/Section/Section";
import axios from "axios";
import { UserConsumer } from "../Components/Auth/UserContext";
import { useOktaAuth } from "@okta/okta-react";
import { PDFExport } from "@progress/kendo-react-pdf";
import {
  MultiColumnComboBox,
  ComboBoxFilterChangeEvent,
  ComboBoxChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { filterBy, FilterDescriptor } from "@progress/kendo-data-query";

import IUserInfo from "../Interfaces/IUserInfo";
import { IEmployee } from "../Interfaces/IEmployee";
import runtimeConfig from "../RuntimeConfig";

type HomeProps = {
  userInfo: IUserInfo | undefined;
};

const columns = [
  { field: "name", header: "Name", width: "300px" },
  { field: "email", header: "Email", width: "300px" },
  { field: "employeeID", header: "BBHR ID", width: "100px" },
];

const Home = ({ userInfo }: HomeProps) => {
  const pdfExportComponent = useRef<PDFExport>(null);
  const exportPDFWithComponent = () => {
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
    }
  };

  const [years, setData] = useState([]);
  const [loading, isLoaded] = useState(false);
  let currentYear: any = new Date();
  const [year, setYear] = useState(currentYear.getFullYear() - 1);
  // const url = process.env.REACT_APP_ENDPOINT_URL + "/api/totalcomp";
  const url =
    (runtimeConfig.endpoint_url || process.env.REACT_APP_ENDPOINT_URL) +
    "/api/totalcomp";
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [currentUserDetails, setCurrentUserDetails] = useState<
    IEmployee | undefined
  >(undefined);
  const [employees, setEmployees] = useState<IEmployee[]>([]);
  const [filter, setFilter] = useState<FilterDescriptor>();
  const { authState, oktaAuth } = useOktaAuth();

  // Data fetches
  const fetchCurrentUserDetails = async () => {
    if (!currentUserDetails) return;

    try {
      const body = {
        id: 0,
        sectionId: 0,
        userId: currentUserDetails.email,
        year: 0,
      };
      const result = await axios.post(url, body);
      setData(result.data);
      isLoaded(true);
    } catch (error) {
      console.log(error);
      isLoaded(false);
    }
  };

  const fetchIsSuperAdmin = async () => {
    if (!userInfo || !userInfo.email || !userInfo.bamboohrId) return;
    if (userInfo.bamboohrId === undefined) return;

    // @ts-ignore
    setCurrentUserDetails({
      ...userInfo,
      employeeID: userInfo.bamboohrId,
    });

    try {
      // const isSuperAdminUrl =
      //   process.env.REACT_APP_ENDPOINT_URL +
      //   `/api/SuperAdmin/IsSuperAdmin/${userInfo.bamboohrId}`;
      const isSuperAdminUrl =
        (runtimeConfig.endpoint_url || process.env.REACT_APP_ENDPOINT_URL) +
        `/api/SuperAdmin/IsSuperAdmin/${userInfo.bamboohrId}`;
      const result = await axios.get(isSuperAdminUrl);
      setIsSuperAdmin(result.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchEmployees = async () => {
    const accessToken = await oktaAuth.getAccessToken();

    try {
      // const employeesUrl =
      //   process.env.REACT_APP_ENDPOINT_URL + "/api/EmployeeList";
      const employeesUrl =
        (runtimeConfig.endpoint_url || process.env.REACT_APP_ENDPOINT_URL) +
        "/api/EmployeeList";
      const result = await axios.get(employeesUrl, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      const employees = result.data.map((employee: IEmployee) => {
        return {
          name: employee.name,
          email: employee.email,
          employeeID: employee.employeeID,
        };
      });
      setEmployees(employees);
    } catch (error) {
      console.log(error);
    }
  };

  // useEffects to fetch data
  useEffect(() => {
    fetchIsSuperAdmin();
  }, [userInfo]);

  useEffect(() => {
    if (!isSuperAdmin) return;

    fetchEmployees();
  }, [isSuperAdmin]);

  useEffect(() => {
    fetchCurrentUserDetails();
  }, [currentUserDetails]);

  const handleFilterChange = (event: ComboBoxFilterChangeEvent) => {
    if (event) setFilter(event.filter);
    if (!event.filter) setCurrentUserDetails(undefined);
  };

  const handleSelectionChange = (event: ComboBoxChangeEvent) => {
    setCurrentUserDetails(event.target.value);
  };

  return (
    <>
      <Welcome />

      <Container>
        <Row>
          <Col className="text-center p-3">
            <h2 className="text-cerulean">
              {userInfo?.name} Total Compensation Statement
            </h2>
          </Col>
        </Row>
        <Row>
          <Col className="text-center p-5">
            <p className="text-cerulean">
              Total compensation is the total value of various types of pay and
              benefits North Wind provides to you. It includes your base wage
              (hourly rate or annual salary), cash bonuses, and the total dollar
              value of the benefit premiums paid by North Wind on your behalf.
              The benefits provided to you include insurance premiums such as
              health, dental, vision, life, accident, and disability. You are
              also offered benefits for paid time off, 401(k) contributions,
              wellness reimbursement, employee assistance programs, and
              education assistance.
            </p>
          </Col>
        </Row>
      </Container>

      <Container>
        <Row className="p-2">
          <Col className="text-sm-left">
            <Form>
              <Form.Group as={Row} className="mb-5" controlId="totalCompYear">
                <Form.Label column sm={1}>
                  Year:
                </Form.Label>

                <Col sm={3}>
                  <Form.Select
                    onChange={(e) => setYear(parseInt(e.target.value))}
                    title="Select Year"
                  >
                    {years.map((year, index) => (
                      <option key={index} value={year} title={year}>
                        {year}
                      </option>
                    ))}
                  </Form.Select>
                </Col>

                {isSuperAdmin && employees && employees.length > 0 && (
                  <Col sm={3}>
                    <MultiColumnComboBox
                      columns={columns}
                      data={filter ? filterBy(employees, filter) : employees}
                      filterable={true}
                      dataItemKey={currentUserDetails?.employeeID}
                      onFilterChange={handleFilterChange}
                      onChange={handleSelectionChange}
                      textField={"name"}
                      placeholder="Select Employee"
                      style={{ width: "300px" }}
                    />
                  </Col>
                )}
              </Form.Group>
            </Form>
          </Col>

          <Col className="text-sm-right ">
            <button
              type="button"
              className="btn btn-gray"
              onClick={exportPDFWithComponent}
            >
              Export to PDF
            </button>
          </Col>
        </Row>
      </Container>

      {loading ? (
        <PDFExport
          ref={pdfExportComponent}
          paperSize={["8.5in", "11in"]}
          margin="1cm"
          fileName={`Report for ${year}`}
          forcePageBreak=".page-break"
          scale={0.25}
          author="North Wind Group"
        >
          <Summary userInfo={currentUserDetails || userInfo} year={year} />

          <div className="table-graphs">
            <Section
              userInfo={currentUserDetails || userInfo}
              sectionId={"total"}
              year={year}
              format={"currency"}
            />

            <Section
              userInfo={currentUserDetails || userInfo}
              sectionId={"salary"}
              year={year}
              format={"currency"}
            />

            <Section
              userInfo={currentUserDetails || userInfo}
              sectionId={"benefits"}
              year={year}
              format={"currency"}
            />

            <Section
              userInfo={currentUserDetails || userInfo}
              sectionId={"retirement"}
              year={year}
              format={"currency"}
            />

            <div className="page-break" />

            <Section
              userInfo={currentUserDetails || userInfo}
              sectionId={"taxes"}
              year={year}
              format={"currency"}
            />

            <Section
              userInfo={currentUserDetails || userInfo}
              sectionId={"pto"}
              year={year}
              format={"number"}
            />

            <Additional />
          </div>
        </PDFExport>
      ) : (
        <>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </>
      )}
    </>
  );
};

const withContext = () => (
  <UserConsumer>{(userInfo) => <Home userInfo={userInfo} />}</UserConsumer>
);

export default withContext;
