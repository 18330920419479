import { Switch, Route, useHistory } from "react-router-dom";
import { Alert } from "react-bootstrap";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { LoginCallback, Security, SecureRoute } from "@okta/okta-react";
import Home from "./Pages/Home";
import "./App.css";
import config from "./oktaConfig";
import { UserProvider } from "./Components/Auth/UserContext";

const oktaAuth = new OktaAuth(config.oidc);

function App() {
  const history = useHistory();

  const restoreOriginalUrl = async (_oktaAuth: any, originalUri: any) => {
    history.replace(toRelativeUrl(originalUri || "/", window.location.origin));
  };

  return (
    <>
      {process.env.NODE_ENV !== "production" && (
        <div className="px-1 py-3">
          <Alert color="danger">
            Running {process.env.NODE_ENV} environment.
          </Alert>
        </div>
      )}

      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUrl}>
        <UserProvider>
          <div className="app">
            <Switch>
              <Route path="/login/callback" component={LoginCallback} />
              <SecureRoute exact path="/" component={Home} />
            </Switch>
          </div>
        </UserProvider>
      </Security>
    </>
  );
}

export default App;
