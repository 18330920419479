import { useState, useEffect } from "react";
import axios from "axios";
import NoDataFound from "../Common/Snippets/NoDataFound";
import "./Table.css";
import { ITable } from "../../Interfaces/ITable";
import IUserInfo from "../../Interfaces/IUserInfo";

import TableType1 from "./TableType1";
import TableType2 from "./TableType2";
import { UserConsumer } from "../Auth/UserContext";
import runtimeConfig from "../../RuntimeConfig";
import { IEmployee } from "../../Interfaces/IEmployee";

interface ITableWithContextProps {
  userInfo: IEmployee | IUserInfo | undefined;
  sectionId: number | undefined;
  sectionTitle: string;
  chartType: string;
  year: number;
  format: string;
}

interface ITableProps {
  sectionId: number | undefined;
  sectionTitle: string;
  chartType: string;
  year: number;
  format: string;
}

const Table = ({
  userInfo,
  sectionId,
  sectionTitle,
  chartType,
  year,
  format,
}: ITableWithContextProps) => {
  const [data, setData] = useState<ITable | null>(null);
  // const url = process.env.REACT_APP_ENDPOINT_URL + "/api/table";
  const url =
    (runtimeConfig.endpoint_url || process.env.REACT_APP_ENDPOINT_URL) +
    "/api/table";

  useEffect(() => {
    const fetchData = async () => {
      if (userInfo && userInfo.email) {
        try {
          const body = {
            id: 0,
            sectionId: sectionId,
            userId: userInfo.email,
            year: year,
          };
          const result = await axios.post(url, body);
          setData(result.data);
        } catch (error) {
          console.log(error);
        }
      }
    };
    fetchData();
  }, [userInfo, year]);

  let tableType = null;
  if (
    chartType.toLowerCase() === "pie" ||
    chartType.toLowerCase() === "donut"
  ) {
    tableType = (
      <TableType1 data={data} sectionTitle={sectionTitle} format={format} />
    );
  } else if (
    chartType.toLowerCase() === "column" ||
    chartType.toLowerCase() === "pietotal"
  ) {
    tableType = (
      <TableType2 data={data} sectionTitle={sectionTitle} format={format} />
    );
  }

  return tableType || <NoDataFound />;
};

// const withContext = (props: ITableProps) => (
//   <UserConsumer>
//     {(userInfo) => <Table {...props} userInfo={userInfo} />}
//   </UserConsumer>
// );

// export default withContext;

export default Table;
