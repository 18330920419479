import { useState, useEffect } from "react";
import {
  Chart,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartArea,
  ChartValueAxis,
  ChartValueAxisItem,
  ChartTooltip,
} from "@progress/kendo-react-charts";
import axios from "axios";
import "hammerjs";
import { IColumnChart } from "../../Interfaces/IColumnChart";
import NoDataFound from "../Common/Snippets/NoDataFound";
import { UserConsumer } from "../Auth/UserContext";
import IUserInfo from "../../Interfaces/IUserInfo";
import { typeFormatter } from "../../Utilities/util";
import runtimeConfig from "../../RuntimeConfig";
import { IEmployee } from "../../Interfaces/IEmployee";

interface IColumnChartWithContextProps {
  userInfo: IEmployee | IUserInfo | undefined;
  sectionId: number | undefined;
  year: number;
}

interface IColumnChartProps {
  userInfo: IEmployee | IUserInfo | undefined;
  sectionId: number | undefined;
  year: number;
}

const ColumnChart = ({
  userInfo,
  sectionId,
  year,
}: IColumnChartWithContextProps) => {
  const [data, setData] = useState<IColumnChart[]>();
  // const url = process.env.REACT_APP_ENDPOINT_URL + "/api/chartcolumn";
  const url =
    (runtimeConfig.endpoint_url || process.env.REACT_APP_ENDPOINT_URL) +
    "/api/chartcolumn";

  useEffect(() => {
    const fetchData = async () => {
      if (userInfo && userInfo.email) {
        try {
          const body = {
            id: 0,
            sectionId: sectionId,
            userId: userInfo.email,
            year: year,
          };
          const result = await axios.post(url, body);
          setData(result.data);
        } catch (error) {
          console.log(error);
        }
      }
    };
    fetchData();
  }, [userInfo, sectionId, url, year]);
  const defaultTooltipRender = ({ point }: any) =>
    `${typeFormatter("currency")?.format(point.value)}`;

  return (
    <>
      {data ? (
        <Chart
          style={{ minHeight: "420px" }}
          onLegendItemClick={(e) => e.preventDefault()}
        >
          <ChartArea background="transparent" />
          <ChartLegend position="bottom" orientation="horizontal" />
          <ChartTooltip render={defaultTooltipRender} />

          <ChartValueAxis>
            <ChartValueAxisItem
              visible={true}
              majorGridLines={{ visible: false }}
              labels={{ format: "{0:C}" }}
              // majorTicks={{ size:5000,skip:1000 }}
              majorUnit={5000}
            />
          </ChartValueAxis>
          <ChartCategoryAxis>
            <ChartCategoryAxisItem
              majorGridLines={{ visible: false, step: 1000 }}
              labels={{ rotation: -45 }}
              line={{ visible: true }}
            />
          </ChartCategoryAxis>
          <ChartSeries>
            {data.map((stack: IColumnChart, idx) => (
              <ChartSeriesItem
                key={idx}
                type="column"
                //spacing={10}
                gap={5}
                //width={10}
                stack={{ type: "normal" }}
                data={stack.series}
                color={stack.color}
                name={stack.name}
                categoryField="category"
                field="value"
              />
            ))}
          </ChartSeries>
        </Chart>
      ) : (
        <NoDataFound />
      )}
    </>
  );
};

// const withContext = (props: IColumnChartProps) => (
//   <UserConsumer>
//     {(userInfo) => <ColumnChart {...props} userInfo={userInfo} />}
//   </UserConsumer>
// );

// export default withContext;
export default ColumnChart;
