import "./Table.css";
import { ITable } from "../../Interfaces/ITable";
import TotalCompTooltip from "../Common/TotalCompTooltip";
import { typeFormatter } from "../../Utilities/util";
import StaticToolTip from "../Common/Snippets/StaticToolTip";

interface TableTypeProps {
  data: ITable | null;
  sectionTitle: string;
  format: string;
}

const HWTooltip = "Includes applicable DBA/SCA fringe rates.";

const TableType2 = ({ data, sectionTitle, format }: TableTypeProps) => {
  const displayNA = (name: string, value: number, format: string) => {
    if (
      name === "Short-Term Disability" ||
      name === "Long-Term Disability" ||
      name === "Life, AD&D, and EAP" ||
      name === "Company Discretionary Contribution"
    ) {
      if (value === 0) {
        return "N/A";
      }
    }
    return typeFormatter(format)?.format(value);
  };

  return (
    <table className="table">
      <thead>
        <tr>
          <th>{sectionTitle} Breakdown</th>
          <th>
            North Wind Contribution{" "}
            {sectionTitle === "Health & Welfare Benefits"
              ? StaticToolTip("997", HWTooltip)
              : null}
          </th>
          <th>Employee Contribution</th>
        </tr>
      </thead>
      <tbody>
        {data?.tableBody.map((row, idx) => (
          <tr key={idx}>
            <td>
              {row.name} &nbsp;
              {row.tooltip && (
                <TotalCompTooltip id={idx.toString()} text={row.tooltip} />
              )}
            </td>
            <td>{typeFormatter(format)?.format(row.value1)}</td>
            <td>{displayNA(row.name, row.value2, format)}</td>
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <td>{data?.tableFooter.name}</td>
          <td>
            {typeFormatter(format)?.format(data?.tableFooter.value1 || 0)}
          </td>
          <td>
            {typeFormatter(format)?.format(data?.tableFooter.value2 || 0)}
          </td>
        </tr>
      </tfoot>
    </table>
  );
};

export default TableType2;
