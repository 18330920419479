import { useState, useEffect } from "react";
import {
  Chart,
  ChartArea,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
} from "@progress/kendo-react-charts";
import axios from "axios";
import "hammerjs";
import { IPieChart } from "../../Interfaces/IPieChart";
import NoDataFound from "../Common/Snippets/NoDataFound";
import { UserConsumer } from "../Auth/UserContext";
import IUserInfo from "../../Interfaces/IUserInfo";
import { typeFormatter } from "../../Utilities/util";
import runtimeConfig from "../../RuntimeConfig";
import { IEmployee } from "../../Interfaces/IEmployee";

interface IPieChartWithContextProps {
  userInfo: IEmployee | IUserInfo | undefined;
  sectionId: number | undefined;
  year: number;
  format: string;
}

interface IPieChartProps {
  userInfo: IEmployee | IUserInfo | undefined;
  sectionId: number | undefined;
  year: number;
  format: string;
}

const PieTotalChart = ({
  userInfo,
  sectionId,
  year,
  format,
}: IPieChartWithContextProps) => {
  const [data, setData] = useState<IPieChart>();
  // const url = process.env.REACT_APP_ENDPOINT_URL + "/api/chartpietotal";
  const url =
    (runtimeConfig.endpoint_url || process.env.REACT_APP_ENDPOINT_URL) +
    "/api/chartpietotal";

  useEffect(() => {
    const fetchData = async () => {
      if (userInfo && userInfo.email) {
        try {
          const body = {
            id: 0,
            sectionId: sectionId,
            userId: userInfo.email,
            year: year,
          };
          const result = await axios.post(url, body);

          setData(result.data);
        } catch (error) {
          console.log(error);
        }
      }
    };
    fetchData();
  }, [userInfo, sectionId, url, year]);

  const labelContent = (props: any) => {
    let formatedNumber;
    formatedNumber = typeFormatter(format)?.format(props.dataItem.value);

    return `${props.dataItem.category} : ${formatedNumber}`;
  };

  return (
    <>
      {data ? (
        <Chart onLegendItemClick={(e) => e.preventDefault()}>
          <ChartArea background="transparent" />
          <ChartLegend position="bottom" visible={false} />
          <ChartSeries>
            <ChartSeriesItem
              type="pie"
              data={data.series}
              field="value"
              categoryField="category"
              labels={{ visible: true, content: labelContent }}
            />
          </ChartSeries>
        </Chart>
      ) : (
        <NoDataFound />
      )}
    </>
  );
};

// const withContext = (props: IPieChartProps) => (
//   <UserConsumer>
//     {(userInfo) => <PieTotalChart {...props} userInfo={userInfo} />}
//   </UserConsumer>
// );

// export default withContext;

export default PieTotalChart;
