import { Row, Col } from "react-bootstrap";

const NoDataFound = () => {
  return (
    <Row>
      <Col>
        <p>No data was found.</p>
      </Col>
    </Row>
  );
};

export default NoDataFound;
