import { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./Summary.css";
import { ISummaryDetail } from "../../Interfaces/ISummaryDetail";
import axios from "axios";

import IUserInfo from "../../Interfaces/IUserInfo";
import { IEmployee } from "../../Interfaces/IEmployee";
import runtimeConfig from "../../RuntimeConfig";

interface ISummaryProps {
  userInfo: IEmployee | IUserInfo | undefined;
  year: number;
}

const Summary = ({ userInfo, year }: ISummaryProps) => {
  const [data, setData] = useState<ISummaryDetail[]>();
  // const url = process.env.REACT_APP_ENDPOINT_URL + "/api/summary";
  const url =
    (runtimeConfig.endpoint_url || process.env.REACT_APP_ENDPOINT_URL) +
    "/api/summary";

  useEffect(() => {
    const fetchData = async () => {
      if (userInfo && userInfo.email && year) {
        try {
          const body = {
            id: 0,
            sectionId: 0,
            userId: userInfo.email,
            year: year,
          };
          const result = await axios.post(url, body);
          setData(result.data);
        } catch (error) {
          console.log(error);
        }
      }
    };
    fetchData();
  }, [userInfo, year]);

  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  return (
    <>
      {data && (
        <Container fluid className="text-white">
          <Row xs={1} md={1} lg={3} className="p-0  ">
            <Col className="p-3 bg-cerulean text-center ">
              <Row className="p-0">
                <Col>
                  <label className="header-title ">{data[0].name}</label>
                  <h1 className="header-value text-left text-sm-center">
                    {formatter.format(data[0].value)}
                  </h1>
                </Col>
                <Col xs={2} className="align-self-center">
                  <span style={{ fontSize: "48px" }}>+</span>
                </Col>
              </Row>
            </Col>

            <Col className="p-3 bg-american-green text-center ">
              <Row className="p-0">
                <Col>
                  <label className="header-title ">{data[1].name}</label>
                  <h1 className="header-value text-left text-sm-center">
                    {formatter.format(data[1].value)}
                  </h1>
                </Col>
                <Col xs={2} className="align-self-center">
                  <span style={{ fontSize: "48px" }}>=</span>
                </Col>
              </Row>
            </Col>

            <Col className="p-3 bg-prussian-blue text-center ">
              <Row className="p-0">
                <Col>
                  <label className="header-title ">{data[2].name}</label>
                  <h1 className="header-value text-left text-sm-center">
                    {formatter.format(data[2].value)}
                  </h1>
                </Col>
                <Col xs={2} className="align-self-center">
                  <span style={{ fontSize: "48px" }}></span>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export default Summary;
