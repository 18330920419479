import { ITable } from "../../Interfaces/ITable";
import Label from "./Label";
import { typeFormatter } from "../../Utilities/util";
import "./Table.css";

interface TableTypeProps {
  data: ITable | null;
  sectionTitle: string;
  format: string;
}

const TableType1 = ({ data, sectionTitle, format }: TableTypeProps) => {
  return (
    <table className="table">
      <thead>
        <tr>
          {sectionTitle === "Total Compensation" ? (
            <>
              <th colSpan={2}>{sectionTitle} Breakdown</th>
            </>
          ) : (
            <>
              <th>{sectionTitle} Breakdown</th>
              <th>North Wind Contribution</th>
            </>
          )}
        </tr>
      </thead>
      <tbody>
        {data?.tableBody.map((row, idx) => (
          <Label key={idx} row={row} idx={idx} format={format} />
        ))}
      </tbody>
      <tfoot>
        <tr>
          <td>{data?.tableFooter.name}</td>
          <td>
            {typeFormatter(format)?.format(data?.tableFooter.value1 || 0)}
          </td>
        </tr>
      </tfoot>
    </table>
  );
};

export default TableType1;
