export const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export const typeFormatter = (type: string) => {
    switch (type) {
        case "currency":
            return new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
            }); 
        case "number":
            return new Intl.NumberFormat("en-US", {
                maximumFractionDigits:0
            }) 
        case "decimal":
            return new Intl.NumberFormat("en-US", {
                maximumFractionDigits:2
            })
    }
}