import { createContext, useEffect, useState } from "react";
import { useOktaAuth } from "@okta/okta-react";

import IUserInfo from "../../Interfaces/IUserInfo";

const UserContext = createContext<IUserInfo | undefined>({
  name: "",
  email: "",
  bamboohrId: "",
});

export function UserProvider({ children }: any) {
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState<IUserInfo>();

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      setUserInfo(undefined);
    } else {
      oktaAuth
        .getUser()
        .then((user) => {
          const info = {
            name: user?.name,
            email: user?.email,
            // @ts-ignore
            bamboohrId: user?.bamboohrId!,
          };
          setUserInfo(info);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [authState, oktaAuth]);

  return (
    <UserContext.Provider value={userInfo}>{children}</UserContext.Provider>
  );
}

export const UserConsumer = UserContext.Consumer;
